





















































































































import Vue from 'vue'
import Component from 'vue-class-component'
import {Watch} from 'vue-property-decorator'
import {vxm} from '@/store'

class Translation {
  public id: number
  public status: string
  public language: string
  public translation: string
  public translationAtLastBlur: string
  public createdAt: string
  public updatedAt: string
  public lastUsedAt: string

  public constructor(data: Record<string, unknown> = null) {
    if (!data) {
      data = {}
    }
    this.id = (data.id as number) || null
    this.status = data.status as string
    this.language = data.language as string
    this.translation = data.translation as string
    this.translationAtLastBlur = this.translation
    this.createdAt = data.createdAt as string
    this.updatedAt = data.updatedAt as string
    this.lastUsedAt = data.lastUsedAt as string
  }

  public update(item: Record<string, unknown>, setId: boolean) {
    if (setId) {
      this.id = item.id as number
    }
    this.status = item.status as string
    this.translation = item.translation as string
    this.translationAtLastBlur = this.translation
  }
}

class Text {
  public text: string
  public translations: Array<Translation>

  public constructor(data: Record<string, unknown>) {
    this.text = (data.text as string) || ''
    this.translations = []
    const inputTranslations = data.translations as Array<Record<string, unknown>>
    for (let i = 0; i < inputTranslations.length; i++) {
      this.translations.push(new Translation(inputTranslations[i]))
    }
  }

  public getTranslationText(language: string): string {
    for (let i = 0; i < this.translations.length; i++) {
      if (this.translations[i].language === language) {
        return this.translations[i].translation
      }
    }
    return '-'
  }

  public getTranslationObject(language: string): Translation {
    for (let i = 0; i < this.translations.length; i++) {
      if (this.translations[i].language === language) {
        return this.translations[i]
      }
    }
    // Normally we'll always find a translation, but in case we don't, we make a dummy,
    // since we'll bind this to an input, and a null will crash it.
    const dummy = new Translation()
    dummy.translation = ''
    return dummy
  }

  public getCreatedAt(language: string): string {
    let obj = this.getTranslationObject(language)
    return obj && obj.createdAt ? obj.createdAt.split(' ')[0] : ''
  }
}

@Component({
  components: {},
})
export default class List extends Vue {
  private selectedLanguageStorageKey = 'eoni18nadm-settings'
  private availableLanguages = [
    {id: 'en', name: 'English'},
    {id: 'sv', name: 'Swedish'},
    {id: 'nb', name: 'Norwegian'},
  ]

  // todo: load from db
  private datasets = [
    {id: 'frontend', name: 'Frontend'},
    {id: 'webshop', name: 'Webshop'},
    {id: 'booking', name: 'Booking'},
    {id: 'backend', name: 'Backend (old)'},
    {id: 'backend-new', name: 'Backend (new)'},
  ]

  private statuses = [
    {id: '', name: 'All'},
    {id: 'NotTranslated', name: 'Not translated'},
    {id: 'Translated', name: 'Translated'},
    {id: 'Unverified', name: 'Unverified'}
  ]

  private lastUsedModes = [
    {id: '', name: 'All'},
    {id: 'Last6Months', name: 'Used/created last 6 months'},
    {id: 'NotLast6Months', name: 'Not used/created last 6 months'},
  ]

  private dataset = ''
  private selectedLanguages = []
  private texts = []
  private search = {}
  private status = ''
  private loading = false
  private debugItem = null
  private debugVisible = false
  private lastUsedMode = ''
  private isWatchActive = false
  private pagination = {
    currentPage: 1,
    itemsPerPage: 100,
    lastPage: 0,
    totalItemsCount: 0,
  }

  public created(): void {
    this.recallSettings()
    this.load()
  }

  private recallSettings() {
    if (localStorage.getItem(this.selectedLanguageStorageKey)) {
      const settings = JSON.parse(localStorage.getItem(this.selectedLanguageStorageKey))
      this.selectedLanguages = settings.selectedLanguages
      this.status = settings.status
      this.dataset = settings.dataset
    } else {
      this.selectedLanguages = ['en']
      this.status = 'NotTranslated'
      this.dataset = 'backend'
    }
  }

  private rememberSettings() {
    localStorage.setItem(
      this.selectedLanguageStorageKey,
      JSON.stringify({
        selectedLanguages: this.selectedLanguages,
        status: this.status,
        dataset: this.dataset,
      }),
    )
  }

  @Watch('selectedLanguages')
  private onChangeSelectedLanguages() {
    if (!this.isWatchActive) {
      return
    }
    this.rememberSettings()
    this.load()
  }

  @Watch('status')
  private onChangeStatus() {
    if (!this.isWatchActive) {
      return
    }
    this.rememberSettings()
    this.load()
  }

  @Watch('dataset')
  private onChangeDataSet() {
    if (!this.isWatchActive) {
      return
    }
    this.rememberSettings()
    this.load()
  }

  @Watch('lastUsedMode')
  private onChangeLastUsedMode() {
    if (!this.isWatchActive) {
      return
    }
    this.rememberSettings()
    this.load()
  }

  @Watch('pagination.currentPage')
  private onChangeCurrentPage() {
    if (!this.isWatchActive) {
      return
    }
    this.rememberSettings()
    this.load()
  }

  @Watch('pagination.itemsPerPage')
  private onChangeItemsPerPage() {
    if (!this.isWatchActive) {
      return
    }
    this.rememberSettings()
    this.load()
  }

  private getLanguageName(id: string): string {
    for (let i = 0; i < this.availableLanguages.length; i++) {
      if (this.availableLanguages[i].id === id) {
        return this.availableLanguages[i].name
      }
    }
    return ''
  }

  private reset() {
    this.search = {}
    this.load()
  }

  private load() {
    this.loading = true
    const search = []
    for (const key in this.search) {
      if (this.search[key]) {
        search.push({
          language: key,
          query: this.search[key],
        })
      }
    }
    this.$axios
      .post('/v4/sysop/i18n/search', {
        page: this.pagination.currentPage,
        perPage: this.pagination.itemsPerPage,
        languages: this.selectedLanguages,
        status: this.status || null,
        dataset: this.dataset,
        lastUsedMode: this.lastUsedMode,
        search,
      })
      .then((response) => {
        this.texts = []
        response.data.data.forEach((v) => {
          this.texts.push(new Text(v))
        })
        this.pagination.lastPage = parseInt(response.data.meta.lastPage)
        this.pagination.totalItemsCount = parseInt(response.data.meta.totalItemsCount)
        this.loading = false
        this.isWatchActive = true
      })
      .catch((err) => {
        this.loading = false
        vxm.alert.onAxiosError(err, 'Fetching translations failed')
      })
  }

  private buildPostPayload(onlyUntranslated: boolean) {
    const data = []
    for (let i = 0; i < this.texts.length; i++) {
      for (let j = 0; j < this.texts[i].translations.length; j++) {
        const t = this.texts[i].translations[j]
        if (onlyUntranslated && t.status === 'Translated') {
          continue
        }
        data.push({
          text: this.texts[i].text,
          id: t.id,
          language: t.language,
          translation: t.translation,
          status: t.status,
          dataset: this.dataset,
        })
      }
    }
    return data
  }

  private save() {
    this.loading = true
    this.$axios
      .post('/v4/sysop/i18n/update', {translations: this.buildPostPayload(false)})
      .then((response) => {
        for (let i = 0; i < response.data.data.length; i++) {
          this.updateTranslation(response.data.data[i], true)
        }
        vxm.alert.success('Successfully saved')
        this.loading = false
      })
      .catch((err) => {
        this.loading = false
        vxm.alert.onAxiosError(err, 'Save failed')
      })
  }

  private googleTranslate() {
    this.loading = true
    this.$axios
      .post('/v4/sysop/i18n/google-translate', {texts: this.buildPostPayload(true)})
      .then((response) => {
        for (let i = 0; i < response.data.data.length; i++) {
          const item = response.data.data[i]
          item.status = 'Unverified'
          this.updateTranslation(item, false)
        }
        vxm.alert.success('Successfully translated')
        this.loading = false
      })
      .catch((err) => {
        this.loading = false
        vxm.alert.onAxiosError(err, 'Translation failed')
      })
  }

  private updateTranslation(item: Record<string, unknown>, setId: boolean) {
    for (let i = 0; i < this.texts.length; i++) {
      if (this.texts[i].text === item.text) {
        for (let j = 0; j < this.texts[i].translations.length; j++) {
          if (this.texts[i].translations[j].language === item.language) {
            this.texts[i].translations[j].update(item, setId)
            return
          }
        }
      }
    }
    console.error('Could not find item to update:', item)
  }

  private getStatusIcon(item: Text, language: string): string {
    switch (item.getTranslationObject(language).status) {
      case 'NotTranslated':
        return 'fa fa-exclamation-circle'
      case 'Translated':
        return 'fa fa-check-circle'
      case 'Unverified':
        return 'fa fa-pause-circle'
      default:
        return ''
    }
  }

  private clickStatusIcon(item: Text, language: string): void {
    const obj = item.getTranslationObject(language)
    if (obj.status === 'NotTranslated') {
      return
    }
    obj.status = obj.status === 'Translated' ? 'Unverified' : 'Translated'
  }

  private onBlurInput(item: Text, language: string): void {
    const obj = item.getTranslationObject(language)
    if (obj.translationAtLastBlur === obj.translation) {
      return
    }
    if (obj.translation) {
      if (obj.status !== 'Unverified') {
        obj.status = 'Translated'
      }
    } else {
      obj.status = 'NotTranslated'
    }
    obj.translationAtLastBlur = obj.translation
  }

  private setDebugItem(item) {
    this.debugItem = item
    this.debugVisible = true
  }

  private setAllToVerified() {
    for (let i = 0; i < this.texts.length; i++) {
      for (let j = 0; j < this.texts[i].translations.length; j++) {
        const t = this.texts[i].translations[j]
        if (!t.translation) {
          t.status = 'NotTranslated'
          continue
        }
        if (t.status === 'Unverified') {
          this.texts[i].translations[j].status = 'Translated'
        }
      }
    }
  }
}
